import * as Sentry from '@sentry/nextjs';
import { FirebaseError } from 'firebase/app';
import { useForm } from 'react-hook-form';

import { AuthForm, SEOHead, toast, translateFirebaseError } from '@mwell-healthhub/commons';
import { useFirebaseAuth } from '@mwell-healthhub/commons/auth/firebase';
import Loader from '@mwell-healthhub/commons/components/Loader';
import { trackEvent, EventName } from '@mwell-healthhub/commons/utils/analytics';

import AuthWrapper from '../../components/templates/AuthWrapper';
import { useLogin } from '../../hooks';

type LoginData = {
  email: string;
  password: string;
};

function LoginPage() {
  const { register, handleSubmit } = useForm<LoginData>();
  const { getFirebaseAuth } = useFirebaseAuth();
  const auth = getFirebaseAuth(process.env.NEXT_PUBLIC_TENANT_ID);

  const { mutate, isLoading: isLoggingIn } = useLogin(auth, {
    onSuccess: () => {
      trackEvent(EventName.LOGIN, {
        method: 'email & password',
      });

      window.location.reload();
    },
    onError: (error) => {
      Sentry.captureException(error);

      toast({
        message: translateFirebaseError((error as FirebaseError).code),
        type: 'error',
      });
    },
  });

  const onSubmit = (data: LoginData) => {
    mutate(data);
  };

  return (
    <AuthWrapper>
      <SEOHead description="Login for Providers" title="Login for Providers | HealthHub" />
      {isLoggingIn && <Loader />}
      <div className="flex h-full flex-col gap-6 p-8 px-5 py-5 md:mt-0 md:justify-between lg:gap-14 lg:px-14 lg:py-16">
        <div>
          <div className="mb-[34px]">
            <h4 className="mb-1.5 text-xl font-semibold md:text-3xl lg:text-2xl">Sign in</h4>
            <div className="hidden text-gray41 lg:block">
              Get started with managing your clinic appointments
            </div>
          </div>
          <AuthForm onSubmit={handleSubmit(onSubmit)} register={register} />
        </div>
      </div>
    </AuthWrapper>
  );
}

export default LoginPage;
